<template>
  <div>
    <!--begin::Inbox App - Compose -->
    <div class="d-flex flex-column-fill flex-lg-row">
      <!--begin::Card-->
      <div class="card w-100">
        <div class="card-header align-items-center" :class="{ 'px-0': isReply }">
          <div class="card-title">
            <h2 v-if="isReply">Reply</h2>
            <h2 v-else>New email</h2>
          </div>
        </div>
        <div class="card-body p-0">
          <!--begin::Form-->
          <form id="kt_inbox_compose_form">
            <!--begin::Body-->
            <div class="d-block">
              <!--begin::To-->
              <div
                class="d-flex align-items-center border-bottom min-h-50px"
                :class="{'px-8': !isReply}"
              >
                <!--begin::Label-->
                <div class="text-dark fw-bolder w-75px">To:</div>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  type="text"
                  class="form-control border-0"
                  name="compose_to"
                  v-model="email.to"
                  data-kt-inbox-form="tagify"
                />
                <!--end::Input-->
              </div>
              <!--end::To-->
              <!--begin::CC-->
              <div
                class="
                  d-flex
                  align-items-center
                  border-bottom
                  min-h-50px
                "
                :class="{'px-8': !isReply}"
                data-kt-inbox-form="cc"
              >
                <!--begin::Label-->
                <div class="text-dark fw-bolder w-75px">Cc:</div>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  type="text"
                  class="form-control border-0"
                  name="compose_cc"
                  v-model="email.cc"
                  data-kt-inbox-form="tagify"
                />
                <!--end::Input-->
              </div>
              <!--end::CC-->
              <!--begin::Subject-->
              <div class="border-bottom">
                <input
                  class="form-control border-0 min-h-45px"
                  :class="{'px-8': !isReply, 'px-0': isReply}"
                  name="compose_subject"
                  placeholder="Subject"
                  v-model="email.subject"
                />
              </div>
              <!--end::Subject-->
              <!--begin::Subject-->
              <div class="border-bottom">
                <div class="row">
                  <div class="col col-md-8">
                    <select
                      class="form-control border-0 min-h-45px"
                      :class="{'px-8': !isReply, 'px-0': isReply}"
                      v-model="emailTemplateIdx"
                      @change="e => onChangeEmailTemplate(e.target.value)"
                    >
                      <option value="" selected>No template</option>
                      <option
                        v-for="(emailTemplateOption, idx) in emailTemplatesOptions"
                        :key="`email-template-option-${idx}`"
                        :value="emailTemplateOption.id"
                      >
                        {{ emailTemplateOption.title }}
                      </option>
                    </select>
                  </div>
                  <div class="col col-md-4">
                    <select
                      v-if="emailTemplateIdx"
                      class="form-control border-0 min-h-45px"
                      :class="{'px-8': !isReply, 'px-0': isReply}"
                      @change="e => onChangeEmailTemplate(emailTemplateIdx, e.target.value)"
                      v-model="emailTemplateLanguage"
                    >
                      <option value="" selected disabled>Language</option>
                      <option
                        v-for="(template, idx) in emailTemplatesOptions.find(e => e.id == emailTemplateIdx).templates"
                        :key="`email-template-option-language-${idx}`"
                        :value="template.language"
                      >
                        {{ template.language }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!--end::Subject-->
              <!--begin::Message-->
              <div id="kt_inbox_form_editor" class="border-0 h-auto mt-4" :class="{'px-3': !isReply}">
                <editor
                  v-model="email.content"
                  api-key="8igvtp1e529xdwdw7evs0t7wu1b111ec7qriiyafxn0arzpp"
                  placeholder="Your message..."
                  :init="{
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap',
                      'searchreplace visualblocks code fullscreen',
                      'print preview anchor insertdatetime media',
                      'paste code help wordcount table',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic |         alignleft aligncenter alignright |         bullist numlist outdent indent | help',
                  }"
                >
                </editor>
              </div>
              <!--end::Message-->
              <!--begin::Attachments-->
              <div
                class="dropzone dropzone-queue py-4"
                :class="{'px-8': !isReply}"
                id="kt_inbox_reply_attachments"
                data-kt-inbox-form="dropzone"
              >
                <div class="dropzone-items">
                  <div
                    class="dropzone-item"
                    v-for="(attachment, idx) in email.attachments"
                    :key="idx"
                  >
                    <!--begin::Dropzone filename-->
                    <div class="dropzone-file">
                      <div
                        class="dropzone-filename"
                        title="some_image_file_name.jpg"
                      >
                        <span data-dz-name="">{{ attachment.filename }}</span>
                        <strong>
                          (<span data-dz-size="">{{ attachment.size }}</span
                          >)</strong
                        >
                      </div>
                      <div class="dropzone-error" data-dz-errormessage=""></div>
                    </div>
                    <!--end::Dropzone filename-->
                    <!--begin::Dropzone toolbar-->
                    <div @click="deleteAttachment(attachment)" class="dropzone-toolbar">
                      <span class="dropzone-delete" data-dz-remove="">
                        <i class="bi bi-x fs-1" />
                      </span>
                    </div>
                    <!--end::Dropzone toolbar-->
                  </div>
                </div>
              </div>
              <!--end::Attachments-->
            </div>
            <!--end::Body-->
            <!--begin::Footer-->
            <div
              class="
                d-flex
                flex-stack flex-wrap
                gap-2
                py-5
                border-top
              "
              :class="{'px-8': !isReply}"
            >
              <!--begin::Actions-->
              <div class="d-flex align-items-center me-3">
                <!--begin::Upload attachment-->
                <div
                  href="#"
                  class="btn btn-icon btn-light-info me-2 mb-2 attachment-input-wrapper"
                  title="Add an attachment"
                >
                  <i class="bi bi-paperclip fs-4" />
                  <input
                    type="file"
                    multiple
                    :name="uploadFieldName"
                    :disabled="isSaving"
                    @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                    accept="image/*,application/pdf"
                    class="input-file"
                  >
                </div>
                <!--end::Upload attachment-->
              </div>
              <!--end::Actions-->
              <!--begin::Toolbar-->
              <div class="d-flex align-items-center">
                <!--begin::Send-->
                <div
                  v-if="sendingEmail"
                  class="btn btn-primary fs-bold me-4 disabled"
                  disabled
                >
                  <span class="disabled">
                    <span class="spinner-border spinner-border-sm align-middle ms-2" />
                    Sending...
                  </span>
                </div>
                <div
                  v-else
                  class="btn btn-primary fs-bold me-4"
                  data-kt-inbox-form="send"
                  @click="sendEmail"
                >
                  <span class="indicator-label">Send</span>
                </div>
                <!--end::Send-->
                <!--begin::Cancel-->
                <div
                  class="btn btn-secondary fs-bold"
                  data-kt-inbox-form="send"
                  @click="cancelEmail"
                >
                  <span class="indicator-label">Cancel</span>
                </div>
                <!--end::Cancel-->
              </div>
              <!--end::Toolbar-->
            </div>
            <!--end::Footer-->
          </form>
          <!--end::Form-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Inbox App - Compose -->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import Editor from "@tinymce/tinymce-vue";
export default {
  name: 'NewEmail',
  components: {
    Editor,
  },
  emits: [ 'emailSent' ],
  props: {
      isReply: {
          type: Boolean,
          required: false,
          default: false,
      },
      threadId: {
          type: String,
          required: false,
      },
      subject: {
          type: String,
          required: false,
      },
      recipient: {
          type: String,
          required: false,
      },
      headers: {
          type: Object,
          required: false,
      },
  },
  data() {
    return {
      email: {
        headers: {},
        threadId: null,
        to: "",
        cc: "",
        subject: "",
        content: "",
        attachments: [],
      },
      sendingEmail: false,
      
      // Options
      emailTemplatesOptions: [],
      emailTemplateIdx: '',
      emailTemplateLanguage: 'EN',

      // Attachments upload
      uploadFieldName: 'attachments',
      isSaving: false,
    };
  },
  mounted() {
    this.resetForm();
    if (this.$route.query.to) {
      this.email.to = this.$route.query.to;
    }
    ApiService
      .query('email-templates', { params: { limit: 100 } })
      .then(({ data: { results } }) => {
        console.log(results);
        this.emailTemplatesOptions = results;
      });
  },
  methods: {
    onChangeEmailTemplate(idx, language) {
      if (idx) {
        ApiService.get('email-templates', idx).then(({ data: emailTemplate }) => {
          if (!emailTemplate) return;

          const emailTemplateInLanguage = language
            ? emailTemplate.templates.find(e => e.language == language)
            : emailTemplate.templates.find(e => e.language == this.emailTemplateLanguage)
              || emailTemplate.templates[0]; // Default to first available language
          
          if (!emailTemplateInLanguage) return;
          this.emailTemplateLanguage = emailTemplateInLanguage.language;

          if (!this.isReply) { this.email.subject = emailTemplateInLanguage.subject; }
          this.email.content = emailTemplateInLanguage.content;
          this.email.attachments = emailTemplateInLanguage.attachments;  
        });
      } else {
        if (!this.isReply) { this.email.subject = ''; }
        this.email.content = '';
        this.email.attachments = [];
      }
    },
    resetForm() {
      if (this.isReply) {
          this.email.to = this.recipient;
          this.email.subject = this.subject.startsWith("Re: ")
            ? this.subject
            : "Re: " + this.subject;
          this.email.headers = this.headers;
          this.email.threadId = this.threadId;
      } else {
        this.email.headers = {};
        this.email.threadId = null;
        this.email.to = "";
        this.email.cc = "";
        this.email.subject = "";
      }

      this.emailTemplateIdx = '';
      this.emailTemplateLanguage = 'EN';
      this.email.content = "",
      this.email.attachments = [],
      this.sendingEmail = false;
      this.isSaving = false;
    },
    deleteAttachment(attachment) {
      this.email.attachments = this.email.attachments.filter(e => e.filename !== attachment.filename);
    },
    humanReadableSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    },
    async filesChange(fieldName, fileList) {
      this.isSaving = true;

      if (!fileList.length) return;

      for (const file of fileList) {
        await new Promise(resolve => {
          const fr = new FileReader();
          fr.addEventListener("load", (e) => {
            this.email.attachments.push({
              filename: file.name,
              size: this.humanReadableSize(file.size),
              path: e.target.result,
            });
            resolve(e.target.result);
          }); 
          fr.readAsDataURL(file);
        });
      }

      this.isSaving = false;
    },
    sendEmail() {
      this.sendingEmail = true;

      const emailData = {
        threadId: this.email.threadId,
        to: this.email.to,
        html: this.email.content,
        subject: this.email.subject,
        attachments: this.email.attachments,
      };

      if (this.email.headers.inReplyTo) {
        emailData.inReplyTo = this.email.headers.inReplyTo;
      }
      if (this.email.headers.references) {
        emailData.references = this.email.headers.references;
      }

      const config = {
        responseToast: {
          text: "Email sent successfully."
        }
      }
      
      ApiService
        .post('gmail/messages', emailData, config)
        .then(res => {
          console.log(res);
          this.resetForm();
          this.$emit('emailSent');
          this.sendingEmail = false;
        })
        .catch(err => {
          console.error(err);
          this.sendingEmail = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-input-wrapper {
  position: relative;
  cursor: pointer;

  input[type=file] {
    cursor: inherit;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: right;
  }
}

.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar {
  z-index: 1000;
}
</style>